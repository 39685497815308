var buttonCTA;
var buttonSubmit;
var form;
var formInput;
var mceSuccessResponse;
var signupFormVisible = false;
var alreadySubscribed = false;

function  ShowSignupForm(form, formInput, buttonCTA, buttonSubmit) {
  // show form
  formInput.style.display = "block";

  HideButtonCTA();

  // show button Submit
  buttonSubmit.className = "mc-embedded-subscribe Button Submit";

  // set bool
  signupFormVisible = true;
}

function HideSignupForm(form, formInput, buttonCTA, buttonSubmit) {

  console.log("HideSignupForm");
  // hide form
  formInput.style.display = "none";

  ShowButtonCTA();

  // hid submit button
  buttonSubmit.className = "mc-embedded-subscribe Button Submit Hide";

  // set bool
  signupFormVisible = false;
}

function ShowButtonCTA() {
  buttonCTA.className = "Button CTA";
}

function HideButtonCTA() {
  buttonCTA.className = "Button CTA Hide";
}

// this method keeps looking for the subscribe success method
// and hides the signup form and CTA button if it finds it
function CheckForSubscribeLoop() {
    setTimeout(function () {

      if (!alreadySubscribed)
      {
          CheckForSubscribeLoop();
      }

      if (alreadySubscribed)
      {
        HideSignupForm(form, formInput, buttonCTA, buttonSubmit);
        HideButtonCTA();
        ShowButtonCTA();
      }

      if (mceSuccessResponse.innerText === "Check your email!")
      {
        alreadySubscribed = true;
      }

    }, 500);
}

document.addEventListener('DOMContentLoaded', function() {

  buttonCTA = document.getElementById("SignupButtonCTA");
  buttonSubmit = document.getElementById("mc-embedded-subscribe");
  form = document.getElementById("mc-embedded-subscribe-form");
  formInput = document.getElementById("mc_embed_signup");
  mceSuccessResponse = document.getElementById("mce-success-response");

  HideSignupForm(form, formInput, buttonCTA, buttonSubmit);

  buttonCTA.onclick = function() {
    if (!signupFormVisible) {
      ShowSignupForm(form, formInput, buttonCTA, buttonSubmit);
    }
  }

  CheckForSubscribeLoop();

});